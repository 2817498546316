import { chunk } from 'src/lib/lodash';
import { db, functions } from 'src/config/firebase';
import { subtractWeeks } from 'src/utils/date';

const dbNews = db.collection('news');
const dbNewsSummaries = db.collection('newsSummaries');
const dbNewsContent = db.collection('newsContent');

//#region news

async function getNewsByFundManager({ fundManagerId, minDateTime }) {
  return (
    await dbNews
      .where('fundManagerId', '==', fundManagerId)
      .where('publishedAt', '>=', minDateTime)
      .where('sentimentPopulated', '==', true)
      .orderBy('publishedAt', 'desc')
      .get()
  ).docs.map(item => item.data());
}

async function getNewsByFundManagers({ fundManagerIds, minDateTime }) {
  const managerIdChunks = chunk(fundManagerIds, 10);
  const newChunks = await Promise.all(
    managerIdChunks.map(fundManagerChunkIds =>
      dbNews
        .where('fundManagerId', 'in', fundManagerChunkIds)
        .where('publishedAt', '>=', minDateTime)
        .where('sentimentPopulated', '==', true)
        .orderBy('publishedAt', 'desc')
        .get()
    )
  );
  return newChunks
    .map(newsChunk => newsChunk.docs.map(doc => doc.data()))
    .reduce((acc, article) => [...acc, ...article], []);
}

async function getNewsSummary({ articleId, summaryLength }) {
  return (await dbNewsSummaries.doc(`${articleId}_${summaryLength}`).get()).data();
}

async function setNewsSummary({ articleId, summaryLength, sentences }) {
  return dbNewsSummaries.doc(`${articleId}_${summaryLength}`).set({ sentences });
}

async function createNewsSummary({ text, url, summaryType, summaryLength }) {
  const res = await functions.httpsCallable('newsSummarize')({
    text,
    url,
    summaryType,
    summaryLength,
  });
  if (res.data.status.code !== '0') {
    throw new Error(res.data.status.msg);
  }
  return res.data.summary;
}

async function getNewsContent({ articleId }) {
  return (await dbNewsContent.doc(articleId).get()).data();
}

async function getRecentNews() {
  const howFarBack = subtractWeeks(1)(new Date('2022-09-22')).toISOString();

  return (
    await dbNews
      .orderBy('publishedAt', 'desc')
      .where('publishedAt', '>=', howFarBack)
      .where('sentimentPopulated', '==', true)
      .get()
  ).docs.map(item => item.data());
}

async function getTickerNews() {
  return (
    await dbNews.orderBy('publishedAt', 'desc').where('sentimentPopulated', '==', true).limit(100).get()
  ).docs.map(item => item.data());
}

//#endregion

export { getNewsByFundManager };
export { getNewsByFundManagers };
export { getNewsSummary };
export { setNewsSummary };
export { createNewsSummary };
export { getNewsContent };
export { getRecentNews };
export { getTickerNews };
