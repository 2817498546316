const ACCOUNT_TYPES = require('../types/account-types');

function userAccountType(user) {
  function isPlan(string) {
    if (!user) return;
    if (typeof user.accountType !== 'string') return;

    return user.accountType.startsWith(string);
  }

  function isStatus(string) {
    if (!user) return;
    if (typeof user.accountType !== 'string') return;

    return user.accountType.endsWith(string);
  }

  function isAccountType(string) {
    if (!user) return;
    if (typeof user.accountType !== 'string') return;
    return user.accountType === string;
  }

  return {
    getPlan() {
      if (!user) return;
      if (typeof user.accountType !== 'string') return;
      return user.accountType.split('-').slice(0, -1).join('-');
    },

    getStatus() {
      if (!user) return;
      if (typeof user.accountType !== 'string') return;
      return user.accountType.split('-').slice(-1)[0];
    },

    setPlan(plan) {
      const status = this.getStatus(user.accountType);
      return { ...user, accountType: [plan, status].join('-') };
    },

    setStatus(status) {
      const plan = this.getPlan();
      return { ...user, accountType: [plan, status].join('-') };
    },

    setFreeTrial() {
      return this.setPlan(ACCOUNT_TYPES.PLANS.FREE_TRIAL);
    },

    setFreeForever() {
      return this.setPlan(ACCOUNT_TYPES.PLANS.FREE_FOREVER);
    },

    setPaidMonthly() {
      return this.setPlan(ACCOUNT_TYPES.PLANS.PAID_MONTHLY);
    },

    setPaidYearly() {
      return this.setPlan(ACCOUNT_TYPES.PLANS.PAID_YEARLY);
    },

    setCanceled() {
      return this.setStatus(ACCOUNT_TYPES.STATUSES.CANCELED);
    },

    setExpired() {
      return this.setStatus(ACCOUNT_TYPES.STATUSES.EXPIRED);
    },

    setTrialing() {
      return this.setStatus(ACCOUNT_TYPES.STATUSES.TRIALING);
    },

    setActive() {
      return this.setStatus(ACCOUNT_TYPES.STATUSES.ACTIVE);
    },

    setDeleted() {
      return this.setStatus(ACCOUNT_TYPES.STATUSES.DELETED);
    },

    ACCOUNT_TYPES,
    isFree: isPlan(ACCOUNT_TYPES.TYPES.FREE),
    isPaid: isPlan(ACCOUNT_TYPES.TYPES.PAID),
    isFreeTrial: isPlan(ACCOUNT_TYPES.PLANS.FREE_TRIAL),
    isFreeForever: isPlan(ACCOUNT_TYPES.PLANS.FREE_FOREVER),
    isPaidMonthly: isPlan(ACCOUNT_TYPES.PLANS.PAID_MONTHLY),
    isPaidYearly: isPlan(ACCOUNT_TYPES.PLANS.PAID_YEARLY),
    isActive: isStatus(ACCOUNT_TYPES.STATUSES.ACTIVE),
    isDeleted: isStatus(ACCOUNT_TYPES.STATUSES.DELETED),
    isExpired: isStatus(ACCOUNT_TYPES.STATUSES.EXPIRED),
    isTrialing: isStatus(ACCOUNT_TYPES.STATUSES.TRIALING) || isAccountType(ACCOUNT_TYPES.FREE_TRIAL_ACTIVE.KEY),
    isCanceled: isStatus(ACCOUNT_TYPES.STATUSES.CANCELED),
  };
}

module.exports = userAccountType;
