function tokenize(...args) {
  return args.join('-');
}

const TYPES = {
  FREE: 'free',
  PAID: 'paid',
};

const INTERVALS = {
  TRIAL: 'trial',
  FOREVER: 'forever',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
};

const STATUSES = {
  ACTIVE: 'active',
  TRIALING: 'trialing',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
  DELETED: 'deleted',
};

const PLANS = {
  FREE_TRIAL: tokenize(TYPES.FREE, INTERVALS.TRIAL),
  FREE_FOREVER: tokenize(TYPES.FREE, INTERVALS.FOREVER),
  PAID_MONTHLY: tokenize(TYPES.PAID, INTERVALS.MONTHLY),
  PAID_YEARLY: tokenize(TYPES.PAID, INTERVALS.YEARLY),
};

const ACCOUNT_TYPES = {
  FREE_TRIAL_ACTIVE: {
    TYPE: TYPES.FREE,
    INTERVAL: INTERVALS.TRIAL,
    STATUS: STATUSES.ACTIVE,
  },
  FREE_TRIAL_CANCELED: {
    TYPE: TYPES.FREE,
    INTERVAL: INTERVALS.TRIAL,
    STATUS: STATUSES.CANCELED,
  },
  FREE_TRIAL_EXPIRED: {
    TYPE: TYPES.FREE,
    INTERVAL: INTERVALS.TRIAL,
    STATUS: STATUSES.EXPIRED,
  },
  FREE_TRIAL_DELETED: {
    TYPE: TYPES.FREE,
    INTERVAL: INTERVALS.TRIAL,
    STATUS: STATUSES.DELETED,
  },

  FREE_FOREVER_ACTIVE: {
    TYPE: TYPES.FREE,
    INTERVAL: INTERVALS.FOREVER,
    STATUS: STATUSES.ACTIVE,
  },
  FREE_FOREVER_CANCELED: {
    TYPE: TYPES.FREE,
    INTERVAL: INTERVALS.FOREVER,
    STATUS: STATUSES.CANCELED,
  },
  FREE_FOREVER_EXPIRED: {
    TYPE: TYPES.FREE,
    INTERVAL: INTERVALS.FOREVER,
    STATUS: STATUSES.EXPIRED,
  },
  FREE_FOREVER_DELETED: {
    TYPE: TYPES.FREE,
    INTERVAL: INTERVALS.FOREVER,
    STATUS: STATUSES.DELETED,
  },

  PAID_MONTHLY_ACTIVE: {
    TYPE: TYPES.PAID,
    INTERVAL: INTERVALS.MONTHLY,
    STATUS: STATUSES.ACTIVE,
  },
  PAID_MONTHLY_CANCELED: {
    TYPE: TYPES.PAID,
    INTERVAL: INTERVALS.MONTHLY,
    STATUS: STATUSES.CANCELED,
  },
  PAID_MONTHLY_TRIALING: {
    TYPE: TYPES.PAID,
    INTERVAL: INTERVALS.MONTHLY,
    STATUS: STATUSES.TRIALING,
  },
  PAID_MONTHLY_DELETED: {
    TYPE: TYPES.PAID,
    INTERVAL: INTERVALS.MONTHLY,
    STATUS: STATUSES.DELETED,
  },

  PAID_YEARLY_ACTIVE: {
    TYPE: TYPES.PAID,
    INTERVAL: INTERVALS.YEARLY,
    STATUS: STATUSES.ACTIVE,
  },
  PAID_YEARLY_CANCELED: {
    TYPE: TYPES.PAID,
    INTERVAL: INTERVALS.YEARLY,
    STATUS: STATUSES.CANCELED,
  },
  PAID_YEARLY_TRIALING: {
    TYPE: TYPES.PAID,
    INTERVAL: INTERVALS.YEARLY,
    STATUS: STATUSES.TRIALING,
  },
  PAID_YEARLY_DELETED: {
    TYPE: TYPES.PAID,
    INTERVAL: INTERVALS.YEARLY,
    STATUS: STATUSES.DELETED,
  },
};

for (const key of Object.keys(ACCOUNT_TYPES)) {
  ACCOUNT_TYPES[key].PLAN = tokenize(ACCOUNT_TYPES[key].TYPE, ACCOUNT_TYPES[key].INTERVAL);
  ACCOUNT_TYPES[key].KEY = tokenize(ACCOUNT_TYPES[key].PLAN, ACCOUNT_TYPES[key].STATUS);
}

ACCOUNT_TYPES.ALL = Object.keys(ACCOUNT_TYPES).map(key => ACCOUNT_TYPES[key].KEY);
ACCOUNT_TYPES.TYPES = TYPES;
ACCOUNT_TYPES.INTERVALS = INTERVALS;
ACCOUNT_TYPES.STATUSES = STATUSES;
ACCOUNT_TYPES.PLANS = PLANS;

module.exports = ACCOUNT_TYPES;
