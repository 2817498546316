import React from 'react';
import PropTypes, { arrayOf, oneOfType, node } from 'prop-types';
import { LocationProvider } from '@reach/router';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CssBaseline, MuiThemeProvider, StylesProvider } from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import { themeDark } from 'src/components/themes';
import { GlobalStyles } from 'src/components/themes/global-styles';
import store from 'src/store/store';
import styled from 'styled-components';
// import { useSetCurrentUserOnAuthStateChange } from 'src/components/Router';

const queryClient = new QueryClient();

const CypressViewport = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

function CurrentUserState({ children }) {
  // useSetCurrentUserOnAuthStateChange();
  return children;
}

function CypressWindow({ children }) {
  const width = Cypress.config('viewportWidth');
  const height = Cypress.config('viewportHeight');

  return (
    <LocationProvider>
      <MuiThemeProvider theme={themeDark}>
        <ThemeProvider theme={themeDark}>
          <StylesProvider injectFirst>
            <CssBaseline>
              <GlobalStyles />
              <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                  <CurrentUserState>
                    <CypressViewport role="viewport" width={width} height={height}>
                      {children}
                    </CypressViewport>
                  </CurrentUserState>
                </QueryClientProvider>
              </Provider>
            </CssBaseline>
          </StylesProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </LocationProvider>
  );
}

CypressWindow.defaultProps = { children: null };

CypressWindow.propTypes = {
  children: oneOfType([arrayOf(PropTypes.node), node]),
};

export default CypressWindow;
