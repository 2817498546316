import { useEffect, useCallback } from 'react';
import { useGet as useGetUser, useUpdate as useUpdateUser } from 'src/services/filter-api/user';
import { useGet as useGetAuth } from 'src/services/filter-api/authentication';

function useAuthenticatedPageView() {
  const auth = useGetAuth();
  const { data: user } = useGetUser(auth.uid);
  const updateUser = useUpdateUser({ invalidate: false }).mutate;

  const updateUserLastSeenAt = useCallback(() => {
    if (!user) return;
    if (!auth) return;
    if (!auth.isAuthenticated) return;
    if (!auth.isVerified) return;
    updateUser({ userId: auth.uid, lastSeenAt: 'TIMESTAMP' });
  }, [auth, updateUser, user]);

  useEffect(() => {
    window.addEventListener('focus', updateUserLastSeenAt);

    return () => {
      window.removeEventListener('focus', updateUserLastSeenAt);
    };
  }, [updateUserLastSeenAt]);

  return true;
}

export default useAuthenticatedPageView;
