import React from 'react';
import { useEffect } from 'react';
import { md } from 'src/config/layout';
import { USAGE_TRACKING_TOPICS, usageTrackingChannel } from 'src/services';
import { useRecentNews, useDedupeNewsArticles } from 'src/hooks';
import { AppLayout, AppPanel, NewsArticles } from 'src/components';
import { parseISO, isBefore, isAfter, subtractHours } from 'src/utils/date';

function NewsPage() {
  const articles = useDedupeNewsArticles({ articles: useRecentNews() }) || [];

  const getArticleIdInUrl = () => {
    const segments = window.location.pathname.split('/').filter(Boolean);
    if (segments.length < 2) return;

    return segments[1];
  };

  function handleChangeSelectedArticle(article) {
    window.history.replaceState(null, article.title, `/news/${article.articleId}`);
  }

  useEffect(() => {
    window.currentNode = null;
    usageTrackingChannel.publish(USAGE_TRACKING_TOPICS.news);
  }, []);

  return (
    <AppLayout>
      <AppPanel
        spanX="full"
        spanY="full"
        minHeight={400}
        title="News Summarizer"
        css={`
          @media (min-width: 0px) and (max-width: ${md.BREAKPOINT}px) {
            min-height: 100vh;
          }
        `}
      >
        <NewsArticles
          title="News Sentiment"
          showSentimentGraph={true}
          sentimentFilter={article => {
            const date = parseISO(article.publishedAt);
            const toDate = new Date('2022-09-22');
            const fromDate = subtractHours(24)(toDate);
            return isBefore(fromDate)(date) && isAfter(toDate)(date);
          }}
          articles={articles}
          defaultSelectedArticleId={getArticleIdInUrl()}
          onSelectedArticle={handleChangeSelectedArticle}
        />
      </AppPanel>
    </AppLayout>
  );
}

export default NewsPage;
